import { useState } from 'react';
import './index.scss';
import { CognyIcon } from '~/assets/icons';
import { injectIntl } from 'react-intl';
import { Briefcase, Lock, Mail, MousePointer, User } from 'react-feather';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { validatePassword } from '~/utils/validateUserInfo';
import {
    BaseFloatMenu,
    Button,
    PasswordValidation,
    Spinner,
    TextField,
} from '~/components/UIElements';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { authenServices } from '~/services';
import { getErrorCode } from '../../utils/getErrorCode';
import { PUBLIC_ROUTES } from '../../components/Router/paths';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import richardAvatar from '~/assets/images/collaborator.png';
import eyLogo from '~/assets/images/ey_logo.png';
import publicisLogo from '~/assets/images/publicis_logo.png';
import danoneLogo from '~/assets/images/danone_logo.png';
import kpmgLogo from '~/assets/images/kpmg_logo.png';
import pwcLogo from '~/assets/images/pwc_logo.png';
import auchanLogo from '~/assets/images/auchan_logo.png';
import bpiLogo from '~/assets/images/bpi_logo.png';
import { useEntityContext, useTemporaryErrorDisplay } from '~/hooks';
import { AuthContextInterface } from '~/interfaces/contexts';
import { AuthContext } from '~/context/auth';

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',

    // just to be store in June
    jobTitle: '',
    company: '',
};

const customerLogos = [
    publicisLogo,
    eyLogo,
    danoneLogo,
    kpmgLogo,
    pwcLogo,
    auchanLogo,
    bpiLogo,
];

const SignUp = ({ intl }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPasswordPolicies, setShowPasswordPolicies] = useState(false);

    const { login } = useEntityContext<AuthContextInterface>(AuthContext);

    const validateSchema = Yup.object().shape({
        firstName: Yup.string().required(
            intl.formatMessage({ id: 'required_field' })
        ),
        lastName: Yup.string().required(
            intl.formatMessage({ id: 'required_field' })
        ),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'email_validate_error' }))
            .required(intl.formatMessage({ id: 'required_field' })),
        password: Yup.string()
            .required(intl.formatMessage({ id: 'required_field' }))
            .test(
                'policy',
                intl.formatMessage({ id: 'weak_password' }),
                (value) => validatePassword(value)?.valid
            ),
        confirmPassword: Yup.string()
            .required(intl.formatMessage({ id: 'required_field' }))
            .when('password', (password, field) => {
                if (password) {
                    return field
                        .required(
                            intl.formatMessage({
                                id: 'different_password_validate_error',
                            })
                        )
                        .oneOf(
                            [Yup.ref('password')],
                            intl.formatMessage({
                                id: 'different_password_validate_error',
                            })
                        );
                }
            }),
    });

    const onSubmit = async (values) => {
        const { email, firstName, lastName, password, company, jobTitle } =
            values;

        analytics.trackGenericEvent(EVENT_NAMES_ENUM.SIGN_UP, {
            email,
            first_name: firstName,
            last_name: lastName,
        });

        setLoading(true);

        const [error, response] = await authenServices.createAccount({
            email,
            password,
            first_name: firstName,
            last_name: lastName,
        });

        if (error) {
            handleError(
                intl.formatMessage({ id: getErrorCode(response.code) })
            );
            setLoading(false);
            return;
        }

        await Swal.fire({
            title: intl.formatMessage({ id: 'account_created_successfully' }),
            text: intl.formatMessage({ id: 'check_email_account' }),
            icon: 'success',
        });

        const { code, internalError, sessionData } = await login({
            email,
            password,
        });

        setLoading(false);

        analytics.trackLogin(sessionData?.user.id as string, {
            email,
            first_name: firstName,
            last_name: lastName,
            company,
            job_title: jobTitle,
        });

        if (internalError) {
            handleError(intl.formatMessage({ id: getErrorCode(code || '') }));
            return;
        }
    };

    // server errors
    const { error, handleError } = useTemporaryErrorDisplay();

    const formik = useFormik({
        initialValues,
        validationSchema: validateSchema,
        onSubmit,
        validateOnChange: false,
    });

    const {
        email,
        password,
        lastName,
        firstName,
        confirmPassword,
        company,
        jobTitle,
    } = formik.values;

    return (
        <div className="dg-signup-page">
            <div className="dg-signup-page__content animate__animated animate__fadeIn">
                <div>
                    <CognyIcon />
                </div>
                <div className="dg-signup-page__content__top">
                    <h2> {intl.formatMessage({ id: 'create_an_account' })} </h2>

                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            label={intl.formatMessage({ id: 'first_name' })}
                            iconBefore={User}
                            inputProps={{
                                value: firstName,
                                onChange: formik.handleChange,
                                name: 'firstName',
                                placeholder:
                                    intl.formatMessage({ id: 'first_name' }) +
                                    '...',
                            }}
                            error={formik.errors.firstName}
                        />
                        <TextField
                            label={intl.formatMessage({ id: 'last_name' })}
                            iconBefore={User}
                            inputProps={{
                                value: lastName,
                                onChange: formik.handleChange,
                                name: 'lastName',
                                placeholder:
                                    intl.formatMessage({ id: 'last_name' }) +
                                    '...',
                            }}
                            error={formik.errors.lastName}
                        />
                        <TextField
                            label={intl.formatMessage({ id: 'email' })}
                            iconBefore={Mail}
                            inputProps={{
                                value: email,
                                onChange: formik.handleChange,
                                name: 'email',
                                type: 'text',
                                placeholder: 'johndoes@example.com',
                            }}
                            error={formik.errors.email}
                        />
                        <TextField
                            label={intl.formatMessage({ id: 'company' })}
                            iconBefore={Briefcase}
                            inputProps={{
                                value: company,
                                onChange: formik.handleChange,
                                name: 'company',
                                placeholder: 'Apple',
                            }}
                            isOptional
                        />
                        <TextField
                            label={intl.formatMessage({ id: 'job_title' })}
                            iconBefore={MousePointer}
                            inputProps={{
                                value: jobTitle,
                                onChange: formik.handleChange,
                                name: 'jobTitle',
                                placeholder: 'CTO',
                            }}
                            isOptional
                        />
                        <div className="dg-signup-page__password">
                            <TextField
                                label={intl.formatMessage({ id: 'password' })}
                                iconBefore={Lock}
                                inputProps={{
                                    value: password,
                                    onChange: formik.handleChange,
                                    name: 'password',
                                    placeholder:
                                        intl.formatMessage({ id: 'password' }) +
                                        '...',
                                    onBlur: () =>
                                        setShowPasswordPolicies(false),
                                    onFocus: () =>
                                        setShowPasswordPolicies(true),
                                }}
                                error={formik.errors.password}
                                isPassword
                            />
                            {showPasswordPolicies && (
                                <BaseFloatMenu top={60} left={0}>
                                    <PasswordValidation
                                        intl={intl}
                                        password={password}
                                    />
                                </BaseFloatMenu>
                            )}
                        </div>
                        <TextField
                            label={intl.formatMessage({
                                id: 'repeat_password',
                            })}
                            iconBefore={Lock}
                            inputProps={{
                                value: confirmPassword,
                                onChange: formik.handleChange,
                                name: 'confirmPassword',
                                placeholder:
                                    intl.formatMessage({
                                        id: 'repeat_password',
                                    }) + '...',
                            }}
                            error={formik.errors.confirmPassword}
                            isPassword
                        />
                        {error && (
                            <span className="dg-signup-page__error">
                                {error}
                            </span>
                        )}

                        <Button
                            disabled={loading}
                            variant="accent"
                            type="submit"
                            onClick={formik.handleSubmit}
                        >
                            {loading ? (
                                <Spinner width="15" height="15" weight="2" />
                            ) : (
                                intl.formatMessage({ id: 'create_account' })
                            )}
                        </Button>
                    </form>
                </div>
                <div className="dg-signup-page__content__bottom">
                    <span>{intl.formatMessage({ id: 'already_member' })}?</span>
                    <Button
                        variant="secondary"
                        onClick={() => navigate(PUBLIC_ROUTES.login)}
                    >
                        {intl.formatMessage({ id: 'sign_in' })}
                    </Button>
                </div>
            </div>
            <div className="dg-signup-page__right  animate__animated animate__fadeIn">
                <div>
                    <p>“{intl.formatMessage({ id: 'richard_testimony' })}”</p>
                    <div>
                        <img src={richardAvatar} alt="richard-N" />
                        <div>
                            <label>Richard N.</label>
                            <span>Senior Consultant, Big Four Cie</span>
                        </div>
                    </div>
                </div>
                <div>
                    <span>
                        {intl.formatMessage({
                            id: 'already_trustd_by_experts',
                        })}
                    </span>
                    <div>
                        {customerLogos.map((src, idx) => (
                            <img alt="company-logo" key={idx} src={src} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const WrappedComponent = injectIntl(SignUp);

export default WrappedComponent;
